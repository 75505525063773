<template>
  <span>
    <span v-if="coordStyle === 'default'">
      {{
        Array.isArray(coordinate)
          ? displayCompleteCoordinates
          : displayCoordinate
      }}
    </span>
    <span v-if="coordStyle === 'copyable'">
      <v-tooltip
        v-model="tooltipVisible"
        bottom
      >
        <template v-slot:activator="{ on }">
          <code
            class="copyable-code"
            @click="copyToClipboard"
            @mouseleave="resetTooltip"
            v-on="on"
          >
            {{ displayCoordinate }}
          </code>
        </template>
        <span>{{ tooltipText }}</span>
      </v-tooltip>
    </span>
  </span>
</template>

<script>
import { log } from '@/constants.js';

export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    coordinate: {
      type: [String, Number, Array],
      default: ''
    },
    coordStyle: {
      type: String,
      default: 'default',
      validator: value => ['default', 'copyable'].includes(value)
    }
  },
  data() {
    return {
      tooltipVisible: false,
      tooltipText: 'Click to copy',
      textChanged: false
    };
  },
  computed: {
    displayCoordinate() {
      const [meteoType] = this.type.split(', ');
      const mapCoordinate = parseFloat(this.coordinate);

      const formattedCoordinate = meteoType === 'Fulminazione'
        ? mapCoordinate.toFixed(4)
        : mapCoordinate.toFixed(2);

      return formattedCoordinate;
    },
    displayCompleteCoordinates() {
      const [meteoType] = this.type.split(', ');
      const [lat, lon] = this.coordinate.map(c => parseFloat(c));

      const formattedLat = meteoType === 'Fulminazione'
        ? lat.toFixed(4)
        : lat.toFixed(2);

      const formattedLon = meteoType === 'Fulminazione'
        ? lon.toFixed(4)
        : lon.toFixed(2);

      return `[${formattedLat};${formattedLon}]`;
    }
  },
  methods: {
    copyToClipboard() {
      const textToCopy = this.displayCoordinate;

      this.tooltipVisible = true;
      this.tooltipText = 'Copied!';
      this.textChanged = true;

      // Use the Clipboard API to write the text to the clipboard
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          log.info('Text copied to clipboard:', textToCopy);
        })
        .catch(error => {
          log.error('Copy to clipboard failed:', error);
        });
    },
    resetTooltip() {
      setTimeout(() => {
        if (this.textChanged) {
          this.tooltipVisible = false;
          this.tooltipText = 'Click to copy';
        }
      }, 300);
    }
  }
};
</script>

<style scoped>
.copyable-code {
  display: inline-block;
  border: 1px dashed #6f6f6f;
  background-color: #f4f4f4;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  user-select: none;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.copyable-code:hover {
  border: 1px dashed #0CA344;
  background-color: #C2E7C9;
}

.copyable-code:active {
  /* A slight scale down effect on click */
  transform: scale(0.95);
}
</style>
